<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import {lookup2,getLookupItemText,trueText,downFile} from '@/apis/common'
import {uploadFile} from '@/apis/file'
import {queryTraning,updateTraningFile,deleteTraning} from '@/apis/traning'
import Vue from "vue";
import Swal from "sweetalert2";
export default {
  page: {
    title: "培训课程管理",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout},
  data() {
      return {
          title: "培训课程管理",
          showType: "list",
          totalRows: 1,
          currentPage: 1,
          perPage: 10,
          pageOptions: [10, 25, 50, 100],
          tableData: [],
          items:[],
          showForm:false,
          statusShow:false,
          curTraning:{},
          saveFileAction:"",
          filter:{
              traningName:""
          },
          lookupRawData:{},
          lookupData:{
              status:[],
          },
          pageRequest:{
              pageNum:1,
              pageSize:10
          }
      };
  },
  computed: {
    surveyUrl(){
      return "http://application.greenpont.com/traning-survey/"+this.curTraning.traningCode
    },
    signUpUrl(){
      return "http://application.greenpont.com/traning-signup/"+this.curTraning.traningCode
    },
    signInUrl(){
      return "http://application.greenpont.com/traning-signin/"+this.curTraning.traningCode
    },
    surveyImg(){
      return "/api-gateway/service/qrcode?content="+encodeURIComponent("http://application.greenpont.com/traning-survey/"+this.curTraning.traningCode)
    },
    signUpImg(){
      return "http://application.greenpont.com/traning-signup/"+this.curTraning.traningCode
    },
    signInImg(){
      return "http://application.greenpont.com/traning-signin/"+this.curTraning.traningCode
    }
  },
  watch:{
      "pageRequest.pageNum"() {
          this.loadData()
      },
      filter:{
          handler(){
              this.loadData();
          },
          deep:true
      }
  },
  created(){
      this.profileFormInit = {...this.profileForm}
      this.init();
      this.loadData();
  },
  mounted() {
  },
  methods: {
      trueText,
      async init(){
          this.lookupRawData = await lookup2("TRANING-STATUS");
          this.lookupData.status = this.lookupRawData["TRANING-STATUS"];
      },
      getParams(){
          let params = {...this.pageRequest,filter:this.filter}
          return params
      },
      loadData(){
          const params = this.getParams();
          queryTraning(params).then((res)=>{
              this.tableData = res.data;
              this.totalRows = res.page.total;
          });
      },
      handleDetail(actionType,order){
          this.$router.push({
            name: "traning-detail",
              query: {
                  traningCode: order.traningCode,
                  action:actionType
              }
          })
      },
      handleCreate(){
          this.$router.push({
              name: "traning-detail",
          })
      },
      formatLookupText(typeCode,value){
          return getLookupItemText(this.lookupRawData[typeCode],value);
      },
      actionEnable(actionType,order){
          if(actionType=='Edit'){
              if(order.status=='C'||order.status=='F'){
                  return false;
              }else{
                  return true;
              }
          }
          if(actionType=="DownloadFile"){
              if(order.downloadFile!=null){
                  return true
              }else {
                  return false
              }
          }
          if(actionType=="Delete"){
              if(order.status=='N'){
                  return true
              }else {
                  return false
              }
          }
          return true;
      },
      statusStyle(status){
          return {
              'badge-soft-info':status=='Q',
              'badge-soft-primary':status=='N',
              'badge-soft-success':status=='FU',
              'badge-soft-warning':status=='P',
              'badge-soft-danger':status=='F',
              'badge-soft-dark':status=='C',               
          }
      },
      myForm(){
          this.$refs.myFormModal.show()
      },
      handleSaveDownloadFile(traning){
          this.curTraning = traning
          this.$refs.attachment.click()
      },
      changeAttachmentFile(){
          if(this.$refs.attachment.files.length==1){
              const formData = new FormData();
              formData.append("files",this.$refs.attachment.files[0]);
              uploadFile(formData).then((res)=>{
                  let fileName = res.data[0].fileOrgName
                  updateTraningFile({traningCode:this.curTraning.traningCode,fileName:fileName}).then(()=>{
                      this.curTraning.downloadFile = fileName
                      Vue.swal("文件已更新");
                  })
              })
          }
      },
      handleDownloadFile (traning){
          const url=`/api-gateway/service/download?fileType=ATT&fileName=${traning.downloadFile}`
          downFile(url,"");
      },
      handleDelete(traningCode){
          Swal.fire({
            text: "培训课程删除将无法撤回!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#34c38f",
            cancelButtonColor: "#f46a6a",
            confirmButtonText: "是的, 确认!",
            cancelButtonText: "取消"
          }).then(result => {
            if (result.value) {
              deleteTraning(traningCode).then(()=>{
                  this.loadData()
              })
            }
          });
      },
      handleUrl(traning){
        this.curTraning = traning
        this.$refs.myFormModal.show()
      }
  }
};

</script>

<template>
  <Layout>
    <div class="row mt-4">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                  <div class="row">
                <div class="row">
                    <div class="col-md-6">
                        <div class="mb-3">
                            <h5 class="card-title">课程列表</h5>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="d-flex flex-wrap align-items-start justify-content-md-end mt-2 mt-md-0 gap-2 mb-3">
                            <div>
                                <ul class="nav nav-pills">
                                    <li class="nav-item">
                                        <a class="nav-link" v-bind:class="showType=='list' ? 'active' : ''" data-bs-toggle="tooltip" title="List" @click="showType='list'"><i class="uil uil-list-ul"></i></a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" v-bind:class="showType=='grid' ? 'active' : ''" data-bs-toggle="tooltip" data-bs-placement="top" title="Grid"  @click="showType='grid'"><i class="uil uil-apps"></i></a>
                                    </li>
                                </ul>
                            </div>
                            <div>
                                <button type="button" class="btn btn-light text-truncate ms-2"  @click="handleCreate">新建</button>
                                <button type="button" class="btn btn-light text-truncate ms-2"  @click="loadData"><i class="ion ion-md-refresh me-1 align-middle"></i> 刷新</button>
                            </div>
                        </div>
                    </div><!-- end col -->
                </div><!-- end row -->
                <div v-if="showType=='grid'">
                    <div class="row">
                        <div class="col-xl-4 col-sm-6" v-for="row in tableData" :key="row.traningCode">
                            <div class="card">
                                <div class="card-body p-4">
                                    <div class="d-flex align-items-start">
                                        <div class="flex-grow-1 overflow-hidden">
                                            <h5 class="font-size-15 mb-1 text-truncate">
                                                <a href="javascript:" class="text-dark"  @click="handleDetail('detail',row)">{{row.traningName}}</a>
                                            </h5>
                                            <span class="badge me-1" :class="statusStyle(row.status)">{{formatLookupText('ORDER-STATUS',row.status)}}</span>
                                        </div>
                                    </div>
                                    <div class="pt-2">
                                        <button type="button" class="btn btn-primary btn-sm text-truncate ms-2"  @click="handleDetail('detail',row)"><i class="typcn typcn-document-text me-1 align-middle"></i> 查看</button>
                                        <b-dropdown dropup variant="primary" class="ms-2" size="sm">
                                            <template v-slot:button-content>
                                                <i class="typcn typcn-cog-outline align-middle"></i> 操作
                                            </template>
                                            <b-dropdown-item @click="handleDetail('detail',row)" ><i class="typcn typcn-document-text me-1 align-middle"></i> 查看</b-dropdown-item>
                                            <b-dropdown-item @click="handleDetail('edit',row)" v-if="actionEnable('Edit',row)" ><i class="typcn typcn-edit me-1"></i> 修改</b-dropdown-item>
                                            <b-dropdown-item @click="handleDelete(row.traningCode)"  v-if="actionEnable('Delete',row)"><i class="typcn typcn-delete-outline me-1"></i> 删除</b-dropdown-item>
                                            <b-dropdown-item @click="handleSaveDownloadFile(row)"><i class="mdi mdi-folder-outline me-1"></i> 上传资料</b-dropdown-item>
                                            <b-dropdown-item @click="handleDownloadFile(row)"  v-if="actionEnable('DownloadFile',row)"><i class="mdi mdi-folder-outline me-1"></i> 下载资料</b-dropdown-item>
                                            <b-dropdown-item @click="handleUrl(row)"><i class="mdi mdi-folder-outline me-1"></i> 培训地址</b-dropdown-item>
                                        </b-dropdown> 
                                    </div>
                                </div>
                                <!-- end card body -->
                            </div><!-- end card -->
                        </div><!-- end col -->
                    </div><!-- end row -->
                </div>
                <b-modal id="modal-standard" title="培训相关地址" title-class="font-18" ref="myFormModal">                    
                    <h5>培训报名地址:</h5>
                    <p>{{ signUpUrl }}</p>
                    <h5>培训签到地址:</h5> 
                     <p>{{ signInUrl }}</p>
                     <h5>问卷调查:</h5> 
                     <p>{{ surveyUrl }}</p>
                     <img :src="surveyImg" width="150" height="150">
                 </b-modal>
                <div v-if="showType=='list'">
                        <table class="table align-middle table-nowrap table-check">
                            <thead>
                                <tr>
                                    <th scope="col">编号</th>
                                    <th scope="col">课程名称</th>
                                    <th scope="col">课程时间</th>
                                    <th scope="col">状态</th>
                                    <th scope="col">更新时间</th>
                                    <th style="width: 80px; min-width: 80px;">操作</th>
                                </tr><!-- end tr -->
                            </thead><!-- end thead -->
                            <tbody>
                                <tr v-for="row in tableData" :key="row.id">
                                    <td><a class="text-body fw-medium" href="javascript:" @click="handleDetail('detail',row)">{{row.id}}</a></td>
                                    <td> <a class="text-body fw-medium" href="javascript:" @click="handleDetail('detail',row)">{{row.traningName}}</a></td>
                                    <td>{{row.traningDate}}</td>
                                    <td><span class="badge me-1" :class="statusStyle(row.status)">{{formatLookupText('TRANING-STATUS',row.status)}}</span></td>
                                    <td>{{row.updateDate}}</td>
                                    <td>
                                        <b-dropdown variant="link" menu-class="dropdown-menu-end">
                                            <template v-slot:button-content>
                                                <button class="btn btn-light btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <i class="uil uil-ellipsis-h"></i>
                                                </button>
                                            </template>
                                            <b-dropdown-item @click="handleDetail('detail',row)" ><i class="typcn typcn-document-text me-1"></i> 查看</b-dropdown-item>
                                            <b-dropdown-item @click="handleDetail('edit',row)" v-if="actionEnable('Edit',row)" ><i class="typcn typcn-edit me-1"></i> 修改</b-dropdown-item>
                                            <b-dropdown-item @click="handleDelete(row.traningCode)"  v-if="actionEnable('Delete',row)"><i class="typcn typcn-delete-outline me-1"></i> 删除</b-dropdown-item>
                                            <b-dropdown-item @click="handleSaveDownloadFile(row)"><i class="mdi mdi-folder-outline me-1"></i> 上传资料</b-dropdown-item>
                                            <b-dropdown-item @click="handleDownloadFile(row)"  v-if="actionEnable('DownloadFile',row)"><i class="mdi mdi-folder-outline me-1"></i> 下载资料</b-dropdown-item>
                                            <b-dropdown-item @click="handleUrl(row)"><i class="mdi mdi-folder-outline me-1"></i> 培训地址</b-dropdown-item>
                                        </b-dropdown>                                               
                                    </td>
                                </tr><!-- end tr -->
                            </tbody><!-- end tbody -->
                        </table><!-- end table -->

                    
                </div>
                <div class="row g-0 text-center text-sm-start" v-if="totalRows>0">
                    <div class="col-sm-6">
                        <div>
                            <p class="mb-sm-0">Showing {{(pageRequest.pageNum-1)*pageRequest.pageSize+1}} to {{pageRequest.pageNum*pageRequest.pageSize}} of {{totalRows}} entries</p>
                        </div>
                    </div>
                    <!-- end col -->
                    <div class="col-sm-6">
                        <ul class="pagination pagination-rounded justify-content-center justify-content-sm-end mb-sm-0">
                            <b-pagination v-model="pageRequest.pageNum" :total-rows="totalRows" :per-page="pageRequest.pageSize"></b-pagination>
                        </ul><!-- end ul -->
                    </div><!-- end col -->
                </div><!-- end row -->      
                <input ref="attachment" type="file" aria-label="Upload your profile picture" placeholder="" style="display: none;" @change="changeAttachmentFile">              
            </div><!-- end card -->
                </div>
            </div>
        </div><!-- end col -->
    </div><!-- end row -->
  </Layout>
</template>
<style lang="scss" scoped>
.finish{
    vertical-align: super;
    color: red;
}
.border-light {
  border-color: #f8f7fc !important;
}
.bg-light {
    background-color: rgba(248, 247, 252,1)!important;
}
.search-box{
  .form-control{
      border-radius: 30px;
      padding-left: 40px;
  }
  .search-icon{
      font-size: 16px;    
      position: absolute;
      left: 13px;
      top: 2px;
      color: #959396;
      line-height: 38px;
  }
}
</style>